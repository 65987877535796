// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// UI
import Button from '@mui/material/Button';
import { accessList } from '../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { api } from '../../../../../../../engine/config/api';
import { handleExportResponse } from '../../../../../../../_helpers/handleExportResponse';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';

function AlloExpressButtonGroups(props) {
  const { sx } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');
  const hasAccessExport = useAccessList(accessList.productsExportPrices);
  const hasAccessImport = useAccessList(accessList.productsImportPrices);
  const isLoading = useSelector(selectors.content.productDownloadPending);
  const selection = useSelector(selectors.contentProduct.selection);
  const filter = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);


  const downloadProductsProperties = async () => {
    const orderBy = convertSortingObjectToUrlParams(sorting);


    const paramsByFilter = filter.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    });
    const data = {
      categoryId,
      attributeSetId,
      products: selection.toArray().join(),
      filter: paramsByFilter,
      orderBy,
    };
    const response = await api.products.exportAlloExpress(data);
    handleExportResponse(response, dispatch, setSuccessMessage, setErrorMessage, t, 'alloExpressProducts.xlsx');
  };
  const handleImport = () => {
    dispatch(actions.setAlloExpressModal({ isOpen: true }));
  };
  return (
    <>
      {hasAccessImport && (
        <Button
          fullWidth
          sx={sx}
          onClick={handleImport}
          disable={isLoading}
          id="import_button"
        >
          {t('Імпорт товарів АЛЛО Express')}
        </Button>
      )}
      {hasAccessExport && (
        <Button
          onClick={downloadProductsProperties}
          fullWidth
          disable={isLoading}
          sx={sx}
          id="export_button"
        >
          {t('Експорт товарів АЛЛО Express')}
        </Button>
      )}
    </>
  );
}


export default AlloExpressButtonGroups;
