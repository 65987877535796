// Core
import { adaptV4Theme, createTheme } from '@mui/material/styles';

export const Z_INDEX = {
  header: 302,
  selectCounterCell: 500,
  selectMenu: 1302, // MUI modal + 2
  allTable: 1,
};

export const COMPONENTS_TYPES = {
  SELECT: {
    secondary: 'secondary',
    dark: 'dark',
  },
  TABLE_CELL_FILTER: {
    inputFilter: 'inputFilter',
    selectFilter: 'selectFilter',
    dateFilter: 'dateFilter',
  },
};

export const COLOR = {
  bgDefault: '#f8f8f8',
  bgError: 'rgba(224, 0, 39, 0.1)',
  success: '#4D9E57',
  error: '#E00027',
  warning: '#EC9727',
  inactive: '#30303060',
  changeColor: '#ff8f00',
  white: '#fff',
  serviceRequestStatusColor: {
    awaitingApprove: '#DB8020',
    signingTheContract: '#006580',
    awaitingPayment: '#DB8020',
    connected: '#4D9E57',
    disabled: '#E00027',
    canceled: '#303030',
  },
  brand: {
    light: '#E0002733',
    main: '#F97988',
    dark: '#E00027',
    cell: '#F6E0E4',
  },
  controls: {
    light: '#5285CC66',
    main: '#7FB5FF',
    dark: '#5285CC',
  },
  controlsIcons: {
    main: '#5E5E5E',
  },
  blue: {
    main: '#5285CC',
    85: 'rgba(82, 133, 204, .85)',
    75: 'rgba(82, 133, 204, .75)',
    40: 'rgba(82, 133, 204, .4)',
    20: 'rgba(82, 133, 204, .2)',
  },
  grey: {
    main: '#303030',
    87: 'rgba(48, 48, 48, 0.87)',
    78: 'rgba(48, 48, 48, 0.78)',
    72: 'rgba(48, 48, 48, 0.72)',
    54: 'rgba(48, 48, 48, 0.54)',
    48: 'rgba(48, 48, 48, 0.48)',
    38: 'rgba(48, 48, 48, 0.38)',
    24: 'rgba(48, 48, 48, 0.24)',
    18: 'rgba(48, 48, 48, 0.18)',
    12: 'rgba(48, 48, 48, 0.12)',
    8: 'rgba(48, 48, 48, 0.08)',
    4: 'rgba(48, 48, 48, 0.04)',
    2: 'rgba(48, 48, 48, 0.02)',
  },
  black: {
    initial: '#303030',
    default: '#404040',
    72: '#6A6A6A',
    54: '#4B4B4B',
    38: '#B0B0B0',
    24: '#D4D4D4',
    12: '#E9E9E9',
    8: '#F0F0F0',
  },
  darkStyle: {
    main: '#303030',
    secondary: '#404040',
    light: '#626262',
    fontColorMain: '#FFFFFF',
    white: {
      72: 'rgba(255, 255, 255, 0.72)',
      54: 'rgba(255, 255, 255, 0.54)',
      24: 'rgba(255, 255, 255, 0.24)',
      38: 'rgba(255, 255, 255, 0.38)',
      8: 'rgba(255, 255, 255, 0.08)',
    },
    brand: {
      main: '#F97988',
      secondary: '#5285CC',
    },
  },
  shadow: {
    block: {
      main: '0 0 8px rgba(0, 0, 0, 0.12)',
    },
  },
};

export const BUTTON_SIZE = {
  small: '9px 22px',
  middle: '11px 22px',
  large: '13px 22px',
  outlinedSmall: '8px 22px',
  outlinedMiddle: '10px 22px',
  outlinedLarge: '12px 22px',
  withIconSmall: '4px 22px',
  withIconMiddle: '5px 22px',
  withIconLarge: '6px 22px',
  outlinedWithIconSmall: '3px 22px',
  outlinedWithIconMiddle: '4px 22px',
  outlinedWithIconLarge: '5px 22px',
  outlinedWithStartIconLarge: '7px 22px',
};

export const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
    },
  },
  palette: {
    primary: {
      light: '#8a1a1c',
      main: '#E00027',
      dark: '#d15153',
      contrastText: '#fff',
    },
    secondary: {
      light: '#72aec9',
      main: '#5285CC',
      dark: '#294453',
      contrastText: '#fff',
    },
    background: {
      default: COLOR.bgDefault,
    },
    error: {
      light: COLOR.error,
      main: COLOR.error,
      dark: COLOR.error,
    },
    dark: {
      light: COLOR.darkStyle.light,
      main: COLOR.darkStyle.secondary,
      dark: COLOR.darkStyle.main,
      contrastText: COLOR.darkStyle.fontColorMain,
    },
  },
  props: {
    MuiCheckbox: {
      color: 'secondary',
    },
    MuiTextField: {
      variant: 'standard',
      size: 'small',
    },
  },
  overrides: {
    Content: {
      content: {
        width: 'auto',
        maxWidth: '100%',
      },
    },
    MuiCard: {
      root: {
        background: '#FFFFFF',
        boxShadow: COLOR.shadow.block.main,
        borderRadius: '5px',
      },
    },
    MuiListItem: {
      root: {
        transition: 'none',
      },
    },
    MuiChip: {
      root: {
        background: 'rgba(255, 255, 255, 0.6)',
        border: `1px solid ${COLOR.grey['24']}`,
        borderRadius: '5px',
        color: COLOR.grey['72'],
        padding: '3px 7px 3px 11px',
        height: 'auto',
        margin: '6px',
        '&:hover': {
          borderColor: COLOR.grey['38'],
          background: COLOR.grey['4'],

          '& .MuiChip-label': {
            color: COLOR.grey['72'],
          },
          '& .MuiChip-deleteIcon': {
            color: COLOR.grey['72'],
          },
        },
        '&:focus': {
          borderColor: 'rgba(12, 12, 12, 0.94)',
          background: 'rgba(255, 255, 255, 0.6)',

          '& .MuiChip-label': {
            color: 'rgba(12, 12, 12, 0.94)',
          },
          '& .MuiChip-deleteIcon': {
            color: 'rgba(12, 12, 12, 0.94)',
          },
        },
        '&.Mui-disabled': {
          opacity: 1,
          borderColor: COLOR.grey['12'],
          background: 'rgba(255, 255, 255, 0.38)',

          '& .MuiChip-label': {
            color: COLOR.grey['38'],
          },
          '& .MuiChip-deleteIcon': {
            color: COLOR.grey['38'],
          },
          '& .MuiChip-icon': {
            opacity: 0.4,
          },
        },
      },
      label: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
        color: 'rgba(12, 12, 12, 0.94)',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0 8px 0 0',
        minWidth: '28px',
        textAlign: 'center',
      },
      deleteIcon: {
        color: COLOR.grey['72'],
        margin: 0,
        fontSize: '16px',
      },
      icon: {
        margin: '0 8px 0 0',
      },
    },
    MuiSwitch: {
      root: {
        padding: 0,
        width: 40,
        height: 24,
        borderRadius: 50,
        overflow: 'visible',
        '&:focus': {
          '& .MuiSwitch-track': {
            boxShadow: `0 0 0 5px ${COLOR.grey['8']}`,
            backgroundColor: COLOR.grey['54'],
          },
          '& .Mui-checked + .MuiSwitch-track': {
            boxShadow: '0 0 0 5px #EEF2FC',
          },
        },
      },
      switchBase: {
        margin: 2,
        padding: 0,
        '&:hover': {
          '& + .MuiSwitch-track': {
            backgroundColor: COLOR.grey['24'],
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: COLOR.grey['38'],
          borderRadius: '50px',
        },
        '&.Mui-checked': {
          color: '#ffffff',
          transform: 'translateX(16px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: COLOR.controls.dark,
          },
          '&:hover': {
            '& + .MuiSwitch-track': {
              backgroundColor: COLOR.controls.main,
            },
          },
        },
        '&.Mui-disabled': {
          '& .MuiSwitch-thumb': {
            opacity: 1,
            backgroundColor: COLOR.grey['24'],
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: COLOR.grey['12'],
          },
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: 'rgba(82, 133, 204, 0.4)',
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: '#ffffff',
            },
          },
        },
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
      },
      paperWidthXs: {
        maxWidth: '380px',
      },
      paperFullWidth: {
        margin: 10,
        width: 'calc(100% - 10px)',
      },
    },
    MuiTooltip: {
      arrow: {
        display: 'none',
      },
      tooltip: {
        fontSize: '12px',
        lineHeight: 1.3,
        color: '#FFF',
        background: COLOR.grey['87'],
        boxShadow: '0 0 12px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '8px 16px',
      },
      popper: {
        zIndex: '1199',
        '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
          margin: '0 0 4px !important',
        },
        '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
          margin: '0 0 0 4px !important',
        },
        '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
          margin: '4px 0 0 !important',
        },
        '&[data-popper-placement*="left"] .MuiTooltip-tooltip': {
          margin: '0 4px 0 0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
      },
    },
    MuiDateCalendar: {
      root: {
        width: '260px',
        height: '295px',
      },
    },
    MuiYearCalendar: {
      root: {
        width: '260px',
        maxHeight: '245px',
      },
    },
    MuiPickersDay: {
      root: {
        width: '33px',
        height: '33px',
        borderRadius: '5px',
        '&:hover': {
          color: 'white',
          borderWidth: '1px',
          borderColor: '#2196f3',
          border: '1px solid',
          backgroundColor: '#5285CC80 !important',
        },
        '&:focus, &:active': {
          color: 'white',
          backgroundColor: '#5285CC !important',
        },
      },
    },
    MuiPickersYear: {
      root: {
        flexBasis: '33.3%',
      },
    },
    MuiInputBase: {
      root: {
        minHeight: 35,
        fontSize: 'inherit',
      },
    },
    MuiTable: {
      root: {
        // borderLeft: '1px solid #d8d8d8',
      },
    },
    MuiTableCell: {
      head: {
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: COLOR.grey['8'],
        padding: '2px 4px',
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
        '&.MuiTableCell-head': {
          padding: '12px 16px 0',
          '&.tableCellWithCounter .CellLayout-container': {
            flexWrap: 'wrap',
          },
          '& .CellLayout-container': {
            minHeight: '42px',
            paddingBottom: '7px',
          },
          '&.TableSelectAllCell-cell': {
            paddingLeft: '0px !important',
          },
          '&.TableFixedCell-fixedCell': {
            backgroundColor: '#EFEFEF',
          },
          '&.TableStubCell-cell': {
            backgroundColor: '#FBFBFB',
          },
        },
      },
      root: {
        textOverflow: 'initial !important',
        // TODO: rewrite this file, get rid of !important
        fontSize: '14px !important',
        borderRight: `1px solid ${COLOR.black['12']}`,
        borderBottom: `1px solid ${COLOR.black['12']}`,
        color: '#303030',
        '@media (max-width: 1279.95px)': {
          padding: 2,
          minHeight: 130,
        },
        '&.MuiTableCell-body.TableCell-cell': {
          padding: '5.5px 16px',
        },
        '& .MuiButtonBase-root.MuiCheckbox-root.TableSelectCell-checkbox': {
          padding: '3px',
        },
        '& .MuiButtonBase-root.MuiCheckbox-root.TableSelectAllCell-checkbox': {
          padding: '3px',
        },
        '&.MuiTableCell-body .MuiChip-root': {
          '&:first-child': {
            marginLeft: 0,
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
    TableContainer: {
      root: {
        zIndex: 0,
      },
    },
    TableFixedCell: {
      fixedCell: {
        position: 'sticky !important',
        backgroundColor: 'inherit',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: COLOR.grey['72'],
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover': {
          color: COLOR.grey['72'],
          '& .MuiTableSortLabel-icon': {
            opacity: 1,
          },
        },
        '&.Mui-active': {
          color: COLOR.grey['72'],
        },
        '& .MuiTableSortLabel-icon': {
          marginLeft: '14px',
          marginRight: '14px',
        },
        '&.Mui-disabled': {
          '& svg': {
            display: 'none',
          },
        },
      },
      icon: {
        opacity: 1,
      },
    },
    MuiButton: {
      root: {
        minWidth: 32,
        borderRadius: 5,
        padding: '9px 22px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: COLOR.grey['8'],
          color: COLOR.grey['24'],
        },
      },
      outlined: {
        lineHeight: 1,
        fontSize: 14,
      },
      contained: {
        lineHeight: 1,
        fontSize: 14,
        '&:hover': {
          backgroundColor: '#F97988',
          boxShadow: 'none',
          '& .MuiTouchRipple-root span': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
        '&:focus': {
          '& .MuiTouchRipple-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
        '&.MuiButton-containedSecondary:hover': {
          backgroundColor: 'rgba(82, 133, 204, 0.4)',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '2px',
        lineHeight: '12px',
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiIconButton: {
      root: {
        '@media (max-width: 1279.95px)': {
          // padding: 2,
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 10,
        '&:last-child': {
          paddingBottom: '20px',
        },
      },
    },
    MuiRadio: {
      root: {
        color: COLOR.grey['38'],
        padding: '4px',
        margin: '4px',
        '& input': {
          zIndex: 2,
        },
        '&:hover': {
          color: COLOR.grey['72'],
          background: 'transparent',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '6px',
            right: '6px',
            bottom: '6px',
            left: '6px',
            borderRadius: '50%',
            backgroundColor: COLOR.grey['8'],
          },
          '& .MuiTouchRipple-root span': {
            backgroundColor: COLOR.grey['8'],
          },
        },
        '&:focus': {
          color: COLOR.grey['72'],
          '& .PrivateSwitchBase-input + span': {
            zIndex: 1,
          },
          '& .MuiTouchRipple-root': {
            backgroundColor: COLOR.grey['8'],
          },
        },
        '&:active': {
          color: COLOR.grey['72'],
          '& .PrivateSwitchBase-input + span': {
            zIndex: 1,
          },
          '& .MuiTouchRipple-root': {
            backgroundColor: COLOR.grey['12'],
          },
        },
        '&.Mui-checked': {
          color: '#5285CC',
          '&:hover': {
            color: '#7FB5FF',
            '&:before': {
              content: 'none',
            },
          },
          '&.Mui-disabled': {
            color: 'rgba(82, 133, 204, 0.4)',
            '&:before': {
              content: 'none',
            },
          },
          '&:focus': {
            color: '#5285CC',
            '& .MuiTouchRipple-root': {
              backgroundColor: '#EEF2FC',
            },
          },
          '&:active': {
            color: '#5285CC',
            '& .MuiTouchRipple-root': {
              backgroundColor: '#EEF2FC',
            },
          },
        },
        '&.Mui-disabled': {
          color: COLOR.grey['24'],
          '&:hover': {
            color: COLOR.grey['72'],
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: COLOR.grey['38'],
        padding: '3px',
        margin: '6px',
        borderRadius: '8px',
        '&.TableSelectCell-checkbox': {
          padding: '3px',
        },
        '& .MuiSvgIcon-root': {
          width: '26px',
          height: '26px',
        },
        '& .MuiTouchRipple-root *': {
          borderRadius: '8px !important',
        },
        '&:hover': {
          color: COLOR.grey['72'],
          background: 'transparent',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '6px',
            right: '6px',
            bottom: '6px',
            left: '6px',
            borderRadius: '4px',
            backgroundColor: COLOR.grey['8'],
          },
          '& .MuiTouchRipple-root span': {
            backgroundColor: COLOR.grey['12'],
          },
        },
        '&:focus': {
          color: COLOR.grey['72'],
          '& .MuiTouchRipple-root': {
            backgroundColor: COLOR.grey['8'],
          },
        },
        '&:active': {
          color: COLOR.grey['72'],
          '& .MuiTouchRipple-root': {
            backgroundColor: COLOR.grey['12'],
          },
        },
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: '#5285CC',
          '&.Mui-disabled': {
            color: 'rgba(82, 133, 204, 0.4)',
          },
          '&:hover': {
            color: '#7FB5FF',
            '&:before': {
              content: 'none',
            },
          },
          '&:focus': {
            color: '#5285CC',
            '& .MuiTouchRipple-root': {
              backgroundColor: '#EEF2FC',
            },
            '& svg path': {
              outline: '1px solid rgba(12, 12, 12, 0.94)',
              borderRadius: '1px',
            },
          },
          '&:active': {
            color: '#5285CC',
            '& .MuiTouchRipple-root': {
              backgroundColor: '#EEF2FC',
            },
          },
        },
        '&.Mui-disabled': {
          color: COLOR.grey['24'],
        },
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableRow-head:first-child': {
          '& .TableStubCell-cell': {
            backgroundColor: 'rgba(239, 239, 239, 1)',
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: COLOR.grey['4'],
          '&:hover': {
            backgroundColor: COLOR.grey['4'],
          },
          '&:focus': {
            backgroundColor: COLOR.grey['4'],
          },
        },
        '&:hover': {
          backgroundColor: COLOR.grey['4'],
        },
      },
    },
    MuiLink: {
      root: {
        color: COLOR.blue.main,
        textDecorationColor: COLOR.blue['40'],
      },
    },
  },
  typography: {
    fontFamily: ['Arial', 'Roboto', '"Helvetica Neue"', 'sans-serif'].join(','),
    h5: {
      fontSize: '1rem',
    },
    subheading: {
      fontSize: '0.8125rem',
    },
    button: {
      fontWeight: 400,
      textTransform: 'initial',
    },
  },
  shape: {
    borderRadius: 4,
  },
}));
