import { useState, useMemo } from 'react';
import declineWord from 'decline-word';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import { api } from '../../../../../../../engine/config/api';
import { selectors } from '../../../../../../../engine/config/selectors';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { actions } from '../../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../../engine/core/contentProduct/saga/asyncActions';
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';

const ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION = 'Додати товари до Алло Express';


const AlloExpressModal = () => {
  const open = useSelector(selectors.contentProduct.alloExpressModalOpen);
  const type = useSelector(selectors.contentProduct.alloExpressModalType);
  const selection = useSelector(selectors.contentProduct.selection);
  const selectedItems = useSelector(selectors.contentProduct.selectedItems);
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const limit = useSelector(selectors.contentProduct.pageSize);
  const filters = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(actions.setAlloExpressModal({ showModal: false }));
  };

  const offset = limit * currentPage;

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(sorting.toJS())
  ), [sorting]);
  const [loading, setIsLoading] = useState(false);
  const isAdd = type === ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION;

  const productWord = declineWord(isAdd ? selectedItems.size : selection.size - selectedItems.size, 'товар', '', 'и', 'ів');


  const noCategoriesToAddText = t('Обрані товари вже додані до функціоналу ALLO Express');
  const noCategoriesToDeleteText = t('Серед обраних - відсутні товари, які можна видалити з сервісу АЛЛО Express');
  const addText = `До функціоналу "ALLO Express" буде додано ${selectedItems.size} ${productWord}. Порожні значення логістичних даних будуть автоматично заповнені значеннями: ШВГ-120см*70см*70см, вага-30кг.`;
  const deleteText = `З функціоналу "ALLO Express" буде видалено ${selection.size - selectedItems.size} ${productWord}. Значення логістичних даних за замовчуванням (ШВГ-120см*70см*70см, вага-30кг) будуть автоматично видалені.`;

  const text = (() => {
    if (isAdd && selectedItems.size) {
      return addText;
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return deleteText;
    }
    if (isAdd) {
      return noCategoriesToAddText;
    }

    return noCategoriesToDeleteText;
  })();

  const buttonText = (() => {
    if (isAdd && selectedItems.size) {
      return 'Додати';
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return 'Видалити';
    }
    return 'OK';
  })();

  const handleSend = async () => {
    if (isAdd && selectedItems.size === 0) {
      onClose();
      return;
    }
    if (!isAdd && ((selection.size - selectedItems.size) === 0)) {
      onClose();
      return;
    }
    setIsLoading(true);
    const products = isAdd ? selectedItems.toArray() : selection.filter(sel => !selectedItems.includes(sel)).toArray();
    const response = await api.products.putProducts({
      products,
      productsValues: {
        alloExpressEnabled: isAdd,
      },
    });
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(response.data?.message, response.data.statusTitle));
      onClose();
      dispatch(actions.setSelection([]));

      const paramsByFilter = filters.reduce((acc, { columnName, value }) => {
        acc[columnName] = value;
        return acc;
      }, {});

      const params = {
        ...paramsByFilter,
        limit,
        offset,
        orderBy,
      };
      dispatch(asyncActions.getListAsync(params));
    } else {
      dispatch(setErrorMessage(response.data?.errors ?? 'Виникла помилка', response.data?.statusTitle));
    }
    setIsLoading(false);
  };

  return (
    <>
      {open && (
      <Modal
        modalOpen={open}
        handleModalToggle={onClose}
        handleModalCancel={onClose}
        title={isAdd ? 'Додавання товарів до функціоналу "ALLO Express"' : 'Видалення товарів з "ALLO Express"'}
        buttonSendIcon={null}
        sendText={buttonText}
        cancelText={'Скасувати'}
        modalMaxWidth="sm"
        handleModalSend={handleSend}
        disabledSend={loading}
      >
        <Box sx={{ whiteSpace: 'pre-line' }}><b>{t('Внимание')}!</b> {text}</Box>
      </Modal>
      )}
    </>
  );
};

export default AlloExpressModal;
