// import { useState, useMemo } from 'react';
// import declineWord from 'decline-word';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import Modal from '../../../../../containers/Modals/Modal/Modal';
// import { api } from '../../../../../../../engine/config/api';
import { selectors } from '../../../../../../engine/config/selectors';
// import { setErrorMessage, setSuccessMessage } from '../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../engine/core/content/saga/asyncActions';

// import { convertSortingObjectToUrlParams } from '../../../../../../_helpers/convertDataToUrlParams';

// const ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION = 'Додати товари до Алло Express';


const AlloExpressWarningPopup = () => {
  const modalInfo = useSelector(selectors.contentProduct.alloExpressWarningPopup);
  const {
    showModal, productId, productsValues, callback,
  } = modalInfo;
  // const type = useSelector(selectors.contentProduct.alloExpressModalType);
  // const selection = useSelector(selectors.contentProduct.selection);
  // const selectedItems = useSelector(selectors.contentProduct.selectedItems);
  // const currentPage = useSelector(selectors.contentProduct.currentPage);
  // const limit = useSelector(selectors.contentProduct.pageSize);
  // const filters = useSelector(selectors.contentProduct.filters);
  // const sorting = useSelector(selectors.contentProduct.sorting);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { categoryId } = useParams();

  const onClose = () => {
    dispatch(actions.setAlloExpressWarningPopup({ showModal: false }));
  };

  const handleSend = () => {
    if (callback) {
      callback();
      return;
    }
    const params = {
      categoryId,
      products: [productId],
      productsValues,
    };
    dispatch(asyncActions.putProductsAsync(params));
    dispatch(actions.setAlloExpressWarningPopup({ showModal: false }));
  };

  const text = t('Введені логістичні дані не дозволяють транспортування товару доставкою АЛЛО Express');

  return (
    <>
      {showModal && (
      <Modal
        modalOpen={showModal}
        handleModalToggle={onClose}
        handleModalCancel={onClose}
        title={t('Внимание')}
        buttonSendIcon={null}
        sendText={'Змінити'}
        cancelText={'Скасувати'}
        modalMaxWidth="sm"
        handleModalSend={handleSend}
      >
        <Box sx={{ whiteSpace: 'pre-line' }}><b>{t('Внимание')}!</b> {text}</Box>

      </Modal>
      )}
    </>
  );
};

export default AlloExpressWarningPopup;
