import Box from '@mui/material/Box';
import React, { forwardRef } from 'react';
import { COLOR } from '../../../containers/App/AppStyles';

// Define colors for each status
export const STATUS_COLORS = {
  NEW: COLOR.blue['85'],
  SENT: COLOR.warning,
  SIGNED_BY_PARTNER: COLOR.serviceRequestStatusColor.awaitingApprove,
  SIGNED_BY_ALLO: COLOR.success,
  ACTIVE: COLOR.success,
  REJECTED_BY_PARTNER: COLOR.error,
  REJECTED_BY_ALLO: COLOR.error,
  ERROR: COLOR.error,
  INACTIVE: '#757575',
};

// Define titles for each status
export const STATUS_TITLES = {
  NEW: 'Новий',
  SENT: 'Відправлений',
  SIGNED_BY_PARTNER: 'Підписаний партнером',
  SIGNED_BY_ALLO: 'Підписаний АЛЛО',
  ACTIVE: 'Активний',
  INACTIVE: 'Неактивний',
  REJECTED_BY_PARTNER: 'Відхилений партнером',
  REJECTED_BY_ALLO: 'Відкликаний АЛЛО',
  ERROR: 'Помилка',
};
export const STATUS_TITLES_FOR_HER = {
  NEW: 'Нова',
  SENT: 'Відправлена',
  SIGNED_BY_PARTNER: 'Підписана партнером',
  SIGNED_BY_ALLO: 'Підписана АЛЛО',
  ACTIVE: 'Активна',
  INACTIVE: 'Неактивна',
  REJECTED_BY_PARTNER: 'Відхилена партнером',
  REJECTED_BY_ALLO: 'Відкликана АЛЛО',
  ERROR: 'Помилка',
};

const DocumentStatusBox = forwardRef(({ status, isHer = false, ...props }, ref) => {
  const title = (isHer ? STATUS_TITLES_FOR_HER[status] : STATUS_TITLES[status]) || 'Невідомий статус';
  const backgroundColor = STATUS_COLORS[status] || '#757575';

  return (
    <>
      <Box
        ref={ref}
        {...props}
        sx={{
          ml: '5px',
          backgroundColor,
          color: COLOR.bgDefault,
          padding: '2px 8px',
          borderRadius: '4px',
          fontSize: '12px',
        }}
      >
        {title}
      </Box>
    </>
  );
});

export default DocumentStatusBox;
