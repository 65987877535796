import { requestStatus } from '../engine/config/api';
import { getFileFromBlob } from './getFileFromBlob';

export const handleExportResponse = (response, dispatch, setSuccessMessage, setErrorMessage, t, fileName) => {
  if (response && response.status >= 200 && response.status < 400) {
    const responseType = typeof response.data;
    let responseData;

    if (responseType === 'object') {
      if (!response.data.status) {
        try {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));
          responseData = JSON.parse(decodedString);
        } catch (err) {
          responseData = {};
        }
      } else {
        responseData = response.data;
      }
    } else {
      responseData = {};
    }

    const { status } = responseData;

    switch (status) {
      case requestStatus.error: {
        const { message } = responseData;
        dispatch(setErrorMessage(message, t('Ошибка')));

        break;
      }

      case requestStatus.success: {
        const { message } = responseData;
        dispatch(setSuccessMessage(message, t('Успех')));

        break;
      }

      default: {
        getFileFromBlob(fileName, response);
        break;
      }
    }
  }
};
