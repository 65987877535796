// Core
import React, {
  memo, useState, useCallback, useMemo,
  useEffect,
} from 'react';
import { isImmutable, List } from 'immutable';
import { makeStyles } from '@mui/styles';
import { AutoSizer } from 'react-virtualized';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
// Parts
import MuiGrid from '@mui/material/Grid/Grid';
import Paper from '@mui/material/Paper';
import {
  SortingState, SelectionState, CustomTreeData,
  EditingState, CustomPaging, TreeDataState,
  RowDetailState, DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, TableFilterRow, TableEditRow, TableTreeColumn,
  TableRowDetail, Table,
  TableEditColumn, DragDropProvider, TableFixedColumns, TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import DxPagingState from './components/DxPagingState';
import DxLoader from './components/DxLoader';
import DxTableRow from './components/DxTableRow';
import DxTableSelection from './components/DxTableSelection/DxTableSelection';
import TableHeaderRowPatched from './components/DxGridSettings/TableHeaderRowPatched/TableHeaderRowPatched';
import EditTableColumnList from './components/DxGridSettings/EditTableColumn/EditTableColumn';
import TableColumnResizing from './components/DxGridSettings/TableColumnResizing';
import TableColumnReordering from './components/DxGridSettings/TableColumnReordering';
import DxFilteringState from './components/DxFilteringState';
import TableToggleAllRowDetail from './components/TableToggleAllRowDetails';
import DxPatchedIntegratedSelection from './components/DxPatchedIntegratedSelection';
import TableFilter from '../TableFilter/TableFilter';
import NoDataCell from './components/NoDataCell';
import TableCell from '../TableCell/TableCell';
import PagingPanelComponent from './components/PagingPanelComponent/PagingPanelComponent';
// Helpers
import { locale } from '../../../../engine/init/i18n';
import DxTableStyles from './DxTableStyles';

const useStyles = makeStyles(DxTableStyles);

function getCurrentColumnsOrderLocale(columnOrder, lang) {
  return columnOrder.map(item => ({
    ...item,
    title: get(item, `lang.${lang}`, item.title),
  }));
}

const getRowId = row => row.id;
// Redux
function DxTable(props) {
  const classes = useStyles();
  const {
    onExpandedRowIdsChange, expandedRowIds, onUserSettings, onOrderChange, onColumnWidthsChange, customPageSizes, noDataCellComponent,
    hasEditableCell, onPageSize, onCurrentPage, rows, columns, editComponent, customCellComponent, currentPage, gridSettings, filtersAction, defaultSorting,
    pageSize, editComponentWidth, totalCount, dataTypeProviderProps, defaultFilters, isLoading, settingAction, name, filters, fixedColumn, rowComponent,
    sorting, onSortingChange, sortingStateColumnExtensions, settingStatus, settingPending, filteringExtensions, showShortText, updateHooks,
    selection, onSelection, rowFilterComponent, selectOnFocus, selectionOffFlag, bottomComponent, customHeaderCellComponent, rowDetailComponent,
    onFiltersChange, getChildRows, treeDataRowIds, tableTreeColumnFor, onTreeDataRowIdsChange, toggleCellComponent, type,
    hiddeRowsLabel, isNotEllipsis, screenHeightFull, staticHeightBlock, customFixedColumn,
  } = props;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const normalizeColumns = useMemo(
    () => isImmutable(columns) ? columns.toJS() : columns,
    [columns],
  );
  const normalizeSorting = useMemo(
    () => isImmutable(sorting) ? sorting.toJS() : sorting,
    [sorting],
  );
  const normalizeGridSettings = useMemo(
    () => isImmutable(gridSettings) ? gridSettings.toJS() : gridSettings,
    [gridSettings],
  );
  const normalizeRows = useMemo(
    () => isImmutable(rows) ? rows.toJS() : rows,
    [rows],
  );
  const normalizeSelection = useMemo(
    () => isImmutable(selection) ? selection.toJS() : selection,
    [selection],
  );
  const [columnWidths, setColumnWidths] = useState();
  const [pagingHeight, setPagingHeight] = useState(48);

  useEffect(
    () => {
      setColumnWidths(map(normalizeGridSettings || normalizeColumns, column => ({
        columnName: column.name,
        width: column.width,
      })));
    },
    [normalizeGridSettings, normalizeColumns],
  );

  // Data
  const dataColumns = useMemo(
    () => normalizeGridSettings
      ? getCurrentColumnsOrderLocale(
        filter(normalizeGridSettings, column => column.visible),
        locale[i18n.language],
      )
      : normalizeColumns,
    [normalizeColumns, normalizeGridSettings, i18n.language],
  );

  // Parts
  const NoData = useCallback(
    dataCell => <NoDataCell colSpan={dataCell.colSpan} isLoading={isLoading} hasList={normalizeRows.length > 0} />,
    [isLoading],
  );
  const CellComponent = useCallback(cellComponentProps => (
    <TableCell
      {...cellComponentProps}
      showShortText={showShortText}
      isNotEllipsis={isNotEllipsis}
    />
  ),
  [showShortText, isNotEllipsis]);
  const EditCellComponent = useCallback(
    (editProps) => {
      const style = { ...editProps.style, padding: 0 };
      return (
        <TableEditColumn.Cell {...editProps} style={style}>
          {editComponent({ ...editProps })}
        </TableEditColumn.Cell>
      );
    }, [editComponent],
  );
  const RowComponent = useCallback((rowProps) => {
    if (rowComponent) {
      return rowComponent({ ...rowProps });
    } if (selectOnFocus) {
      return <DxTableRow {...rowProps} />;
    }
    return <VirtualTable.Row {...rowProps} bgcolor="#fff">{rowProps.children}</VirtualTable.Row>;
  }, [rowComponent, selectOnFocus]);

  const handlerReset = () => dispatch(settingAction({ reset: `${name}GridSettings` }));
  const putSettings = () => dispatch(settingAction());

  return (
    <>
      {settingStatus && normalizeGridSettings
      && (
        <EditTableColumnList
          name={name}
          onUserSettings={onUserSettings}
          columns={getCurrentColumnsOrderLocale(normalizeGridSettings, locale[i18n.language])}
          settingStatus={settingStatus}
          settingPending={settingPending}
          handlerReset={handlerReset}
          putSetting={putSettings}
        />
      )}
      <MuiGrid className={classes.positionRelative}>
        <AutoSizer
          disableHeight={type !== 'virtual'}
          disableWidth={type !== 'virtual'}
          style={screenHeightFull ? { height: '100%' } : {}}
        >
          {({ height, width }) => {
            const heightBlock = height - pagingHeight;
            const style = {
              width,
              ...(screenHeightFull
                ? {
                  height: '100%',
                }
                : {}),
            };
            return (
              <Paper style={style}>
                <Grid
                  rows={normalizeRows}
                  columns={dataColumns}
                  getRowId={getRowId}
                  sx={screenHeightFull ? { height: '100%' } : {}}
                >
                  {treeDataRowIds && (
                    <TreeDataState
                      expandedRowIds={treeDataRowIds.toJS()}
                      onExpandedRowIdsChange={onTreeDataRowIdsChange}
                    />
                  )}
                  {!isEmpty(dataTypeProviderProps) && (
                    <DataTypeProvider
                      {...dataTypeProviderProps}
                    />
                  )}
                  {onSelection && <SelectionState selection={normalizeSelection} onSelectionChange={onSelection} />}
                  <DxPagingState
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageSize={onPageSize}
                    onCurrentPage={onCurrentPage}
                    totalCount={totalCount}
                  />
                  {isNumber(totalCount) && <CustomPaging totalCount={totalCount} />}
                  {Boolean(treeDataRowIds && getChildRows) && (
                    <CustomTreeData
                      getChildRows={getChildRows}
                    />
                  )}
                  <DxFilteringState
                    filteringExtensions={filteringExtensions}
                    filtersAction={filtersAction}
                    onFiltersChange={onFiltersChange}
                    defaultFilters={defaultFilters}
                    filters={filters}
                  />
                  {onSortingChange
                  && (
                    <SortingState
                      sorting={normalizeSorting}
                      onSortingChange={onSortingChange}
                      columnExtensions={sortingStateColumnExtensions}
                      defaultSorting={defaultSorting}
                    />
                  )}
                  {onSelection && (
                    <DxPatchedIntegratedSelection
                      selectionOffFlag={selectionOffFlag}
                    />
                  )}
                  {rowDetailComponent && (
                    <RowDetailState
                      expandedRowIds={expandedRowIds.toJS()}
                      onExpandedRowIdsChange={onExpandedRowIdsChange}
                    />
                  )}
                  {editComponent && <EditingState onCommitChanges={null} />}
                  {normalizeGridSettings && <DragDropProvider />}
                  {
                    type === 'virtual'
                      ? (
                        <VirtualTable
                          height={staticHeightBlock || heightBlock}
                          cellComponent={customCellComponent || (hasEditableCell ? TableCell : CellComponent)}
                          columnExtensions={columnWidths}
                          rowComponent={RowComponent}
                          noDataCellComponent={noDataCellComponent || NoData}
                        />
                      )
                      : (
                        <Table
                          cellComponent={customCellComponent || (hasEditableCell ? TableCell : CellComponent)}
                          columnExtensions={columnWidths}
                          rowComponent={RowComponent}
                          noDataCellComponent={noDataCellComponent || NoData}
                        />
                      )
                  }
                  {normalizeGridSettings && settingStatus && (
                    <TableColumnResizing
                      onColumnWidthsChange={onColumnWidthsChange}
                      setColumnWidths={setColumnWidths}
                      gridSettings={normalizeGridSettings}
                      name={name}
                    />
                  )}
                  {normalizeGridSettings && (
                    <TableColumnReordering
                      onOrderChange={onOrderChange}
                      gridSettings={normalizeGridSettings}
                      name={name}
                    />
                  )}
                  {editComponent
                  && (
                    <>
                      <TableEditRow />
                      <TableEditColumn
                        width={editComponentWidth}
                        cellComponent={EditCellComponent}
                      />
                    </>
                  )}
                  {onSelection && <DxTableSelection selectionOffFlag={selectionOffFlag} />}
                  <TableHeaderRowPatched
                    showSortingControls={onSortingChange}
                    settingStatus={settingStatus}
                    customHeaderCellComponent={customHeaderCellComponent}
                  />
                  {rowFilterComponent
                    ? <TableFilterRow cellComponent={TableFilter} rowComponent={rowFilterComponent} />
                    : <TableFilterRow cellComponent={TableFilter} />}
                  {Boolean(treeDataRowIds && tableTreeColumnFor) && (
                    <TableTreeColumn for={tableTreeColumnFor} />
                  )}
                  {fixedColumn
                  && <TableFixedColumns leftColumns={[customFixedColumn, TableEditColumn.COLUMN_TYPE, TableSelection.COLUMN_TYPE]} />}
                  {rowDetailComponent && <TableToggleAllRowDetail />}
                  {onPageSize && onCurrentPage
                  && (
                  <PagingPanelComponent
                    rowsLength={normalizeRows.length}
                    totalCount={totalCount}
                    customPageSizes={customPageSizes}
                    bottomComponent={bottomComponent}
                    updateHooks={updateHooks}
                    isLoading={isLoading}
                    setPagingHeight={setPagingHeight}
                    hiddeRowsLabel={hiddeRowsLabel}
                  />
                  )}
                  {rowDetailComponent && (
                    <TableRowDetail
                      toggleCellComponent={toggleCellComponent || TableRowDetail.ToggleCell}
                      contentComponent={rowDetailComponent}
                    />
                  )}
                </Grid>
              </Paper>
            );
          }}
        </AutoSizer>
        <DxLoader
          isLoading={isLoading}
          hasList={normalizeRows.length > 0}
        />
      </MuiGrid>
    </>
  );
}

DxTable.propTypes = {
  fixedColumn: PropTypes.bool,
  showShortText: PropTypes.bool,
  editComponent: PropTypes.func,
  rowComponent: PropTypes.func,
  customCellComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  onSortingChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onColumnWidthsChange: PropTypes.func,
  filtersAction: PropTypes.func,
  onFiltersChange: PropTypes.func,
  onPageSize: PropTypes.func,
  onCurrentPage: PropTypes.func,
  editComponentWidth: PropTypes.number,
  rows: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  rowDetailComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  bottomComponent: PropTypes.node,
  noDataCellComponent: PropTypes.node,
  dataTypeProviderProps: PropTypes.object,
  onSelection: PropTypes.func,
  onExpandedRowIdsChange: PropTypes.func,
  onUserSettings: PropTypes.func,
  expandedRowIds: PropTypes.instanceOf(List),
  getChildRows: PropTypes.func,
  onTreeDataRowIdsChange: PropTypes.func,
  treeDataRowIds: PropTypes.instanceOf(List),
  tableTreeColumnFor: PropTypes.string,
  selectionOffFlag: PropTypes.string,
  sorting: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  selection: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  gridSettings: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  defaultFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  filters: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  defaultSorting: PropTypes.array,
  customPageSizes: PropTypes.array,
  filteringExtensions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool,
  settingAction: PropTypes.func,
  sortingStateColumnExtensions: PropTypes.array,
  settingStatus: PropTypes.bool,
  settingPending: PropTypes.bool,
  name: PropTypes.string,
  updateHooks: PropTypes.func,
  hiddeRowsLabel: PropTypes.bool,
  hasEditableCell: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  type: PropTypes.oneOf(['static', 'virtual']),
  rowFilterComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  customHeaderCellComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  toggleCellComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  isNotEllipsis: PropTypes.string,
  screenHeightFull: PropTypes.bool,
  staticHeightBlock: PropTypes.number,
  customFixedColumn: PropTypes.symbol,
};

DxTable.defaultProps = {
  filters: List(),
  sorting: List(),
  selection: List(),
  rows: List(),
  toggleCellComponent: null,
  editComponentWidth: 48,
  columns: List(),
  expandedRowIds: List(),
  defaultFilters: List(),
  pageSize: 20,
  currentPage: 0,
  isLoading: false,
  fixedColumn: false,
  showShortText: false,
  selectOnFocus: false,
  hiddeRowsLabel: false,
  hasEditableCell: false,
  type: 'virtual',
};

export default memo(DxTable);
