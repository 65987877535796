// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_ORDERS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_ORDERS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_ORDERS_INITIAL_STATE_ASYNC, path);
  },
  getOrdersHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_ORDERS_HASH_ID_ASYNC, hashId);
  },
  putOrdersHashIdAsync(data) {
    return getActionCreator(asyncTypes.PUT_ORDERS_HASH_ID_ASYNC, data);
  },
  getOrdersStatusesListAsync() {
    return getActionCreator(asyncTypes.GET_ORDERS_STATUSES_LIST_ASYNC);
  },
  getOrdersPaymentTypesAsync(params) {
    return getActionCreator(asyncTypes.GET_ORDERS_PAYMENT_TYPES_ASYNC, params);
  },
  getOrdersShippingTypesAsync() {
    return getActionCreator(asyncTypes.GET_ORDERS_SHIPPING_TYPES_ASYNC);
  },
  downloadCSVAsync(params) {
    return getActionCreator(asyncTypes.GET_ORDERS_DOWNLOAD_ASYNC, params);
  },
  putOrderUnbindShippingTrackingAsync(hashId) {
    return getActionCreator(asyncTypes.PUT_ORDER_UNBIND_SHIPPING_TRACKING, hashId);
  },
  postOrdersUpload(data) {
    return getActionCreator(asyncTypes.POST_ORDERS_UPLOAD_ASYNC, data);
  },
  getOrdersStatisticsAsync(filters) {
    return getActionCreator(asyncTypes.GET_ORDERS_STATISTICS_ASYNC, filters);
  },
});
