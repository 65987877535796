// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_ORDERS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_ORDERS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_ORDERS_FILTERS, filters);
  },
  setOrdersStatusesListPending(pending) {
    return getActionCreator(types.SET_ORDERS_STATUSES_LIST_PENDING, pending);
  },
  setOrdersExpandedRowIds(ids) {
    return getActionCreator(types.SET_ORDERS_EXPANDED_ROW_IDS, ids);
  },
  setReload(reload) {
    return getActionCreator(types.SET_ORDERS_RELOAD, reload);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_ORDERS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_ORDERS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_ORDERS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_ORDERS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_ORDERS_SETTING_PENDING, settingPending);
  },
  setOrderItemByHashId(orderItem) {
    return getActionCreator(types.SET_ORDERS_ITEM_BY_HASH_ID, orderItem);
  },
  mergeOrderEdit(data) {
    return getActionCreator(types.MERGE_ORDER_EDIT, data);
  },
  setOrdersStatusesList(ordersStatusesList) {
    return getActionCreator(types.SET_ORDERS_STATUSES_LIST, ordersStatusesList);
  },
  setPaymentTypes(paymentTypes) {
    return getActionCreator(types.SET_ORDERS_PAYMENT_TYPES, paymentTypes);
  },
  setShippingTypes(shippingTypes) {
    return getActionCreator(types.SET_ORDERS_SHIPPING_TYPES, shippingTypes);
  },
  setIsDownloadFullOrdersLoading(isLoading) {
    return getActionCreator(types.SET_IS_DOWNLOAD_FULL_ORDERS_LOADING, isLoading);
  },
  mergeOrdersUpload(data) {
    return getActionCreator(types.MERGE_ORDERS_UPLOAD, data);
  },
  mergeOrderUnbindShippingTracking(data) {
    return getActionCreator(types.MERGE_ORDER_UNBIND_SHIPPING_TRACKING, data);
  },
  setOrdersDownloadCsv(isLoading) {
    return getActionCreator(types.SET_ORDERS_DOWNLOAD_CSV, isLoading);
  },
  mergeOrdersStatistics(data) {
    return getActionCreator(types.MERGE_ORDERS_STATISTICS, data);
  },
  setCSVData(csv) {
    return getActionCreator(types.SET_ORDERS_CSV_DATA, csv);
  },
  mergeErrorModal(data) {
    return getActionCreator(types.MERGE_ERROR_MODAL, data);
  },
});
