// Core
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Field, reduxForm, formValueSelector, reset,
} from 'redux-form/immutable';
import SaveIcons from '@mui/icons-material/Save';
// Parts
import Modal from '../../../../../containers/Modals/Modal/Modal';
import ImportResultXlsxBlock from './ImportResult/ImportResultXlsxBlock';
import ModalStyle from '../../../../../containers/Modals/Modal/ModalStyle';
import RenderFilesUploader from './ProductsPricesImportModal/components/RenderFilesUploader';
// Engine
import { mimeTypeExtensions } from '../../../../../../engine/config/mimeTypeExtensions';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/content/actions';
// Helpers
import { validators } from '../../../../../../_helpers/validationsForm';
import { useStyles } from '../../../../../hooks/useStyles';
import { api } from '../../../../../../engine/config/api';
import { setSuccessMessage, setErrorMessage } from '../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { usePrevious } from '../../../../../hooks/usePrevious';


export const form = Object.freeze({
  name: 'ProductsAlloExpressImportModal',
  fields: Object.freeze({
    productsFile: 'productsFile',
  }),
});
const selector = formValueSelector(form.name);

function AlloExpressImportModal(props) {
  const {
    pending,
    pristine, valid, submitting, handleSubmit,
  } = props;
  const classes = useStyles(ModalStyle);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const importModal = useSelector(selectors.content.importAlloExpressModal);
  const productsImportPending = useSelector(selectors.content.productsImportPending);
  const feedproductsFile = useSelector(state => selector(state, form.fields.productsFile));

  const prevModal = usePrevious(importModal);

  useEffect(() => {
    if (prevModal === true && importModal === false) {
      dispatch(reset(form.name));
    }
  }, [importModal, dispatch, prevModal]);

  const handleModalToggle = () => {
    dispatch(actions.setAlloExpressModal({ isOpen: false }));
  };

  const onFormSubmit = async (values) => {
    const { productsFile } = values.toJS();

    const response = await api.products.importAlloExpress({ productsFile });
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(response.data.message, response.data.statusTitle));
    } else {
      dispatch(setErrorMessage(response.data?.errors, response.data.statusTitle));
      handleModalToggle();
    }
  };

  return (
    <Modal
      modalOpen={importModal}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      addText={t('Сохранить')}
      iconSendButton={<SaveIcons />}
      disabledSend={pristine || submitting || productsImportPending || !valid || !feedproductsFile || !feedproductsFile.length}
      title={t('Імпорт товарів Алло Express')}
      loadingSend={productsImportPending}
      handleModalAdd={handleSubmit}
      modalMaxWidth="xs"
      fullScreen={false}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          allowFileTypeValidation
          acceptedFileTypes={[mimeTypeExtensions.xlsx, mimeTypeExtensions.xls]}
          component={RenderFilesUploader}
          loadFileFromSaga
          labelFileTypeNotAllowed={t('Тип файла не валидный')}
          name={form.fields.productsFile}
          disabled={pending}
          className="import_modal"
          validate={[validators.itemsRequiredValidator]}
        />
        <ImportResultXlsxBlock classes={classes} importModal={importModal} />
      </Form>
    </Modal>
  );
}


export default reduxForm({
  form: form.name,
})(AlloExpressImportModal);
